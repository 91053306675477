import React, { useState, useEffect, useRef } from "react"
import { launchNewWindow } from "utils/windowUtils"
import { tracking } from "utils/tracking"
import FacebookIcon from "assets/svg/facebook.svg"
import TwitterIcon from "assets/svg/twitter.svg"
import PinterestIcon from "assets/svg/pinterest.svg"
import EmailIcon from "assets/svg/email.svg"
import LinkIcon from "assets/svg/link.svg"
import {
  Container,
  HiddenInput,
  IconLink,
  Space,
  EmailLink,
  CopyContainer,
  CopyText,
} from "./BlogPostShare.styles"

export const BlogPostShare = () => {
  const copyRef = useRef(null)
  const [showCopyText, setShowCopyText] = useState(false)
  const [title, setTitle] = useState("")
  const [url, setUrl] = useState("")
  useEffect(() => {
    setTitle(encodeURIComponent(window.document.title))
    const rawUrl = window.location.href
    const slashLessUrl = rawUrl.endsWith("/")
      ? rawUrl.replace(/\/$/, "")
      : rawUrl
    setUrl(encodeURI(slashLessUrl))
  }, [])
  useEffect(() => {
    if (showCopyText) {
      setTimeout(() => {
        setShowCopyText(false)
      }, 2000)
    }
  }, [showCopyText])
  const copyToClipboard = () => {
    setShowCopyText(true)
    copyRef.current.select()
    document.execCommand("copy")
  }
  return (
    <Container>
      <IconLink
        aria-label="Share On Facebook"
        onClick={() => {
          tracking.shareButtonClicked("facebook", window.document.title)
          launchNewWindow(`https://www.facebook.com/sharer/sharer.php?u=${url}`)
        }}
      >
        <FacebookIcon role="img" aria-label="Facebook Logo" />
      </IconLink>
      <Space />
      <IconLink
        aria-label="Share on Twitter"
        onClick={() => {
          tracking.shareButtonClicked("twitter", window.document.title)
          launchNewWindow(
            `https://twitter.com/intent/tweet?url=${url}%2F&text=${title}`
          )
        }}
      >
        <TwitterIcon role="img" aria-label="Twitter Logo" />
      </IconLink>
      <Space />
      <IconLink
        aria-label="Share on Pinterest"
        onClick={() => {
          tracking.shareButtonClicked("pinterest", window.document.title)
          launchNewWindow(
            `http://pinterest.com/pin/create/button/?url=${url}&description=${title}`
          )
        }}
      >
        <PinterestIcon role="img" aria-label="Pinterest Logo" />
      </IconLink>
      <Space />
      <EmailLink aria-label="Share By Email" href={`mailto:?subject=${title}`}>
        <EmailIcon role="img" aria-label="Email Logo" />
      </EmailLink>
      <Space />
      <CopyContainer>
        <IconLink aria-label="Copy To Clipboard" onClick={copyToClipboard}>
          <LinkIcon role="img" aria-label="Copy Logo" />
        </IconLink>
        <HiddenInput type="text" ref={copyRef} value={url} readOnly />
        <CopyText role="alert" $showCopyText={showCopyText}>
          Link Copied!
        </CopyText>
      </CopyContainer>
    </Container>
  )
}
