import { GatsbyImage } from "gatsby-plugin-image"
import { rgba } from "polished"
import styled from "styled-components"
import { mq, typography } from "styles"

export const Container = styled.div`
  padding: 24px 8.33vw;
  ${mq.minWidth("md")} {
    padding: 32px 6.25vw;
  }
  ${mq.minWidth("lg")} {
    padding: 48px 4.16vw;
  }

  background: ${({ theme }) => theme.dark.canvas?.primary?.opacityHex};
`

export const Title = styled.h1<{ $color: string }>`
  ${typography.blogHeader};
  color: ${({ $color, theme }) =>
    $color || theme.dark.text?.primary?.opacityHex};
  text-align: center;
  margin-bottom: 40px;
  ${mq.minWidth("md")} {
    margin-bottom: 56px;
  }
  ${mq.minWidth("lg")} {
    margin-bottom: 48px;
  }
`

export const Posts = styled.div`
  display: grid;
  grid-gap: 64px;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  ${mq.minWidth("md")} {
    grid-gap: 64px 48px;
    grid-template-columns: 1fr 1fr;
  }
  ${mq.minWidth("lg")} {
    grid-gap: 64px 40px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

export const PostContainer = styled.div`
  cursor: pointer;

  a {
    color: ${({ theme }) => theme.dark?.text?.primary?.opacityHex};
    transition: all 0.3s ease;
  }

  &:hover a {
    color: ${({ theme }) => rgba(theme.dark?.text?.primary?.opacityHex, 0.72)};
  }
`

export const PostTitle = styled.h2`
  ${typography.h4};
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  a {
    ${typography.defaultLink}
    border-bottom: none;
  }
`

export const TagContainer = styled.div`
  margin-bottom: 8px;
`

export const Image = styled(GatsbyImage)`
  margin-bottom: 12px;
`
