import { Link } from "gatsby"
import styled from "styled-components"
import { typography } from "styles"

export const Tag = styled.span<{ $color: string }>`
  display: block;
  ${typography.bodySmall};
  color: ${({ $color }) => $color};
`

export const TagLink = styled(Link)`
  text-decoration: none;
`
