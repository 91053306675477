import { NoStyleButton } from "components/UI/Button/NoStyleButton"
import styled from "styled-components"
import { mq } from "styles"

export const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  ${mq.minWidth("lg")} {
    position: absolute;
    grid-auto-flow: row;
    align-content: center;
    left: 36px;
    top: 0;
    bottom: 0;
  }
`

export const IconLink = styled(NoStyleButton)`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.dark?.ui?.icon?.opacityHex};
  transition: color 0.3s ease;
  ${mq.minWidth("lg")} {
    &:hover {
      color: ${({ theme }) => theme.dark.ui?.iconHover?.opacityHex};
    }
  }
`

export const EmailLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.dark?.ui?.icon?.opacityHex};
  transition: color 0.3s ease;
  ${mq.minWidth("lg")} {
    &:hover {
      color: ${({ theme }) => theme.dark.ui?.iconHover?.opacityHex};
    }
  }
`

export const Space = styled.span`
  padding: 0 30px 0 0;
  ${mq.minWidth("lg")} {
    padding: 0 0 30px 0;
  }
`

export const HiddenInput = styled.input`
  position: absolute !important;
  left: -9999px;
  top: -9999px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  z-index: 0;
`

export const CopyContainer = styled.div`
  position: relative;
`

export const CopyText = styled.span<{ $showCopyText: boolean }>`
  font-size: 10px;
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  font-family: ${({ theme }) => theme.typography?.body1?.typeface?.fontName};
  white-space: nowrap;
  position: absolute;
  left: 50%;
  bottom: -16px;
  transition: all 0.2s ease;
  opacity: ${({ $showCopyText }) => ($showCopyText ? 1 : 0)};
  visibility: ${({ $showCopyText }) => ($showCopyText ? "visibile" : "hidden")};
  transform: ${({ $showCopyText }) =>
    $showCopyText ? "translate(-50%, 0)" : "translate(-50%, -3px)"};
`
