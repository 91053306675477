import { BlogCategoryPost } from "components/BlogCategory/BlogCategoryPost"
import { renderBlock } from "components/Helpers/ComponentRenderer"
import { BlogTag } from "components/UI/BlogTag/BlogTag"
import { PortableText } from "components/UI/PortableText/PortableText"
import React, { useContext } from "react"
import { ThemeContext, ThemeProvider } from "styled-components"
import { SanityBlogPost } from "typings/graphql"
import { blogCatPath } from "utils/blogUtils"
import { humanizeDate } from "utils/dateUtils"
import { getImage } from "utils/imageUtils"
import {
  Container,
  Title,
  Img,
  ImgContainer,
  HeaderContent,
  PostContent,
  PostHeader,
  PostBody,
  Meta,
  MetaEyelash,
  MetaContent,
  MorePosts,
  MoreTitle,
  MoreCategory,
  MoreContainer,
  TagContainer,
} from "./BlogPost.styles"
import { BlogPostShare } from "./BlogPostShare"

type Props = {
  post: SanityBlogPost
  morePosts: Partial<SanityBlogPost>[]
}

export const BlogPost = ({ post, morePosts }: Props) => {
  const themeContext = useContext(ThemeContext)
  const image = post.image
  const tag = post.tags[0]
  const color = post.primaryCategory?.color.opacityHex
  const category = post.primaryCategory.title
  const altDescription = image?.asset?.description || `Image for ${post.title}`
  return (
    <ThemeProvider theme={{ localTheme: themeContext["dark"] }}>
      <Container>
        <PostHeader>
          <HeaderContent>
            {tag && (
              <TagContainer>
                <BlogTag color={color} tag={tag} />
              </TagContainer>
            )}
            <Title>{post?.title}</Title>
            <Meta>
              <div>
                <MetaEyelash>Published</MetaEyelash>
                <MetaContent>{humanizeDate(post.date)}</MetaContent>
              </div>
              <div>
                <MetaEyelash>Author</MetaEyelash>
                <MetaContent>{post.author?.title}</MetaContent>
              </div>
            </Meta>
            <BlogPostShare />
          </HeaderContent>
          <ImgContainer>
            {image && <Img {...image} alt={altDescription} />}
          </ImgContainer>
        </PostHeader>
        <PostBody>
          <PostContent>
            <PortableText
              blocks={post._rawContent}
              keepLastElementBottomPadding
            />
          </PostContent>
        </PostBody>
        {post.blocks?.map((block, index) => renderBlock(block, index))}
        {morePosts?.length > 0 && (
          <MoreContainer>
            <MoreTitle>More articles from</MoreTitle>{" "}
            <MoreCategory $color={color}>{category}</MoreCategory>
            <MorePosts>
              {morePosts?.map(post => (
                <BlogCategoryPost
                  key={post.id}
                  title={post.title}
                  link={blogCatPath(post)}
                  image={getImage(post.image)}
                  tag={post.tags[0]}
                  tagColor={post.primaryCategory?.color.opacityHex}
                />
              ))}
            </MorePosts>
          </MoreContainer>
        )}
      </Container>
    </ThemeProvider>
  )
}
