import React from "react"
import { graphql } from "gatsby"
import { Query, SanityBlogPost } from "typings/graphql"
import { App } from "components/App/App"
import { setTheme } from "helpers/Theme"
import { BlogPost } from "components/BlogPost/BlogPost"
import { blogCatPath } from "utils/blogUtils"

type BlogPostTemplateProps = {
  data: Query
  pageContext: {
    morePosts: Partial<SanityBlogPost>[]
  }
}

const BlogPostTemplate = ({ data, pageContext }: BlogPostTemplateProps) => {
  const theme = setTheme(
    data.sanityTypography,
    data.sanityDarkTheme,
    data.sanityLightTheme,
    data.sanityGlobalTheme
  )

  const seoData = {
    seo: data.sanityBlogPost?.seo,
    path: blogCatPath(data?.sanityBlogPost),
  }

  return (
    <App theme={theme} layout="page" mode="dark" seo={seoData}>
      <BlogPost post={data.sanityBlogPost} morePosts={pageContext.morePosts} />
    </App>
  )
}

export default BlogPostTemplate

export const categoryQuery = graphql`
  query blogPostQuery($id: String!) {
    sanityBlogPost(id: { eq: $id }) {
      ...BlogPost
    }
    sanityTypography {
      ...Typography
    }
    sanityDarkTheme {
      ...DarkTheme
    }
    sanityLightTheme {
      ...LightTheme
    }
    sanityGlobalTheme {
      ...GlobalTheme
    }
  }
`
