import React from "react"
import { Tag, TagLink } from "components/UI/BlogTag/BlogTag.styles"
import { SanityBlogTag } from "typings/graphql"
import { blogTagPath } from "utils/blogUtils"

type Props = {
  tag: SanityBlogTag
  color: string
}

export const BlogTag = ({ tag, color }: Props) => {
  return (
    <TagLink to={blogTagPath(tag)}>
      <Tag $color={color}>{tag?.title}</Tag>
    </TagLink>
  )
}
