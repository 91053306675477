import React from "react"
import { Link } from "gatsby"
import {
  PostTitle,
  Image,
  TagContainer,
  PostContainer,
} from "./BlogCategory.styles"
import { IGatsbyImageDataWithDesc } from "typings/modules"
import { SanityBlogTag } from "typings/graphql"
import { BlogTag } from "components/UI/BlogTag/BlogTag"

type Props = {
  link: string
  image: IGatsbyImageDataWithDesc
  title: string
  tagColor: string
  tag: SanityBlogTag
}

export const BlogCategoryPost = ({
  link,
  image,
  title,
  tagColor,
  tag,
}: Props) => {
  const altDescription = image?.description || `Image for ${title}`
  return (
    <PostContainer>
      <Link to={link}>
        <Image image={image?.gatsbyImage} alt={altDescription} />
      </Link>
      {tag && (
        <TagContainer>
          <BlogTag color={tagColor} tag={tag} />
        </TagContainer>
      )}
      <PostTitle>
        <Link to={link}>{title}</Link>
      </PostTitle>
    </PostContainer>
  )
}
