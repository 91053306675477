import { Images } from "components/UI/PortableText/MultiImage"
import { VideoEmbed } from "components/UI/PortableText/PortableText.styles"
import Image from "gatsby-plugin-sanity-image"
import styled from "styled-components"
import { mq, typography } from "styles"

export const Container = styled.div`
  background: ${({ theme }) => theme.dark.canvas?.secondary?.opacityHex};
`

export const PostHeader = styled.div`
  display: grid;
  align-items: center;
  ${mq.minWidth("lg")} {
    grid-template-columns: 1fr 1fr;
    padding-top: 0;
    position: relative;
  }
`

export const Title = styled.h1`
  ${typography.h2};
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  padding-bottom: 24px;
  ${mq.minWidth("lg")} {
    padding-bottom: 32px;
  }
`

export const ImgContainer = styled.div`
  order: 1;
  padding-bottom: 100%;
  position: relative;
  & > div {
    padding-bottom: 0 !important;
  }
  ${mq.minWidth("lg")} {
    order: 2;
  }
`

export const Img = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`

export const HeaderContent = styled.div`
  order: 2;
  padding: 32px 8.33vw 64px;
  ${mq.minWidth("md")} {
    padding: 48px 12.5vw 96px;
  }
  ${mq.minWidth("lg")} {
    padding: 0 8.33vw;
    order: 1;
  }
`

export const PostBody = styled.div`
  background: ${({ theme }) => theme.dark.canvas?.primary?.opacityHex};
  padding: 64px 0;
  ${mq.minWidth("md")} {
    padding: 96px 0;
  }
  ${mq.minWidth("lg")} {
    padding: 96px 0;
  }
`

export const PostContent = styled.article`
  margin: auto;
  & > * {
    width: 83.33vw;
    margin: auto;
  }
  ${mq.minWidth("md")} {
    & > * {
      width: 75vw;
      margin: auto;
    }
  }
  ${mq.minWidth("lg")} {
    & > * {
      width: 50vw;
      margin: auto;
    }
  }
  ${VideoEmbed} {
    width: 100vw;
    margin: 0 0 0 -8.33vw;
  }
  ${VideoEmbed},
  ${Images},
  figure {
    ${mq.minWidth("md")} {
      width: 87.5vw;
      margin: 0 0 0 -6.25vw;
    }
    ${mq.minWidth("lg")} {
      width: 75vw;
      margin: 0 0 0 -12.5vw;
    }
  }
`

export const TagContainer = styled.div`
  margin-bottom: 12px;
  ${mq.minWidth("md")} {
    margin-bottom: 24px;
  }
`

export const Meta = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid ${({ theme }) => theme.dark.ui?.divider?.opacityHex};
  padding-bottom: 24px;
  margin-bottom: 32px;
  ${mq.minWidth("lg")} {
    border-top: 1px solid ${({ theme }) => theme.dark.ui?.divider?.opacityHex};
    padding-top: 32px;
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 0;
  }
`

export const MetaEyelash = styled.span`
  ${typography.bodySmall};
  display: block;
  color: ${({ theme }) => theme.dark.text?.secondary?.opacityHex};
  margin-bottom: 2px;
`

export const MetaContent = styled.p`
  ${typography.body};
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
`

export const MoreContainer = styled.div`
  padding: 64px 8.33vw;
  ${mq.minWidth("md")} {
    padding: 80px 6.25vw;
  }
  ${mq.minWidth("lg")} {
    padding: 96px 4.16vw;
  }
`
export const MorePosts = styled.div`
  display: grid;
  grid-gap: 64px;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  ${mq.minWidth("md")} {
    grid-gap: 48px;
    grid-template-columns: 1fr 1fr;
  }
  ${mq.minWidth("lg")} {
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

export const MoreTitle = styled.h3`
  ${typography.h4};
  display: inline-block;
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  margin-bottom: 32px;
  ${mq.minWidth("md")} {
    margin-bottom: 48px;
  }
`

export const MoreCategory = styled.h3<{ $color: string }>`
  ${typography.h4};
  display: inline-block;
  color: ${({ $color }) => $color};
  font-style: italic;
  margin-bottom: 32px;
  ${mq.minWidth("md")} {
    margin-bottom: 48px;
  }
`
